<template>
    <div class="d-flex flex-sm-column flex-md-row flex-wrap my-auto">
        <div class="d-sm-inline-flex d-md-flex form-inline">
            <div class="navy-text mr-2">SORT BY</div>
            <sort-by
                class="f-box mt-1"
                :options="sortBy.options"
                @input="filterChanged('sortBy', $event)"
                ref="sortBy"
            />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <filter-box class="mt-1" 
                :options="type.options"
                v-model="type.selected"
                searchable
                labelSearchPlaceholder="Type"

                @input="filterChanged('type', $event)"
                />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <filter-box class="mt-1" 
                :options="quality.options"
                v-model="quality.selected"
                searchable
                labelSearchPlaceholder="Quality"
                
                @input="filterChanged('quality', $event)"
                />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <filter-box class="mt-1" 
                :options="color.options"
                v-model="color.selected"
                searchable
                labelSearchPlaceholder="Paint"

                @input="filterChanged('paint', $event)"
                />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <filter-box class="mt-1" 
                :options="craftable.options"
                v-model="craftable.selected"
                searchable

                labelSearchPlaceholder="Craftable"
                @input="filterChanged('craftable', $event)"
            />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <price-ranger 
                @input="filterChanged('price', $event)"
                ref="priceRanger"
            />
        </div>
        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <button class="btn btn-primary btn-primary-fancy" style="padding: 5px 5px 5px 5px;" @click="clearFilters(); $event.target.blur();">Clear All</button>
        </div>
    </div>
</template>

<script>
import FilterBox from '../../../components/FilterBox'
import SortBy from '@/components/SortBy'
import PriceRanger from "@/components/PriceRanger.vue";
import { EventBus } from "../../../utils/eventBus.js";

export default {
    data() {
        return {
            sortBy: {
                selected: { value: "bestdeal", text: 'Best Deal' },
                options: [
                    { value: "bestdeal", text: 'Best Deal' },
                    { value: "price", text: 'Price', order: true, },
                ]
            },
            type: {
                selected: { value: null, text: null },
                options: [
                    { value: "Any", text: "Any" },
                    { value: "Action", text: "Action" },
                    { value: "Building", text: "Building" },
                    { value: "Cosmetic", text: "Cosmetic" },
                    { value: "Craft Item", text: "Craft Item" },
                    { value: "Crate", text: "Crate" },
                    { value: "Festivizer", text: "Festivizer" },
                    { value: "Gift", text: "Gift" },
                    { value: "Killstreak Kit", text: "Killstreak Kit" },
                    { value: "Melee weapon", text: "Melee weapon" },
                    { value: "Package", text: "Package" },
                    { value: "Party Favor", text: "Party Favor" },
                    { value: "Primary PDA", text: "Primary PDA" },
                    { value: "Primary weapon", text: "Primary weapon" },
                    { value: "Professional Killstreak Kit", text: "Professional Killstreak Kit" },
                    { value: "Recipe", text: "Recipe" },
                    { value: "Secondary PDA", text: "Secondary PDA" },
                    { value: "Secondary weapon", text: "Secondary weapon" },
                    { value: "Server Enchantment", text: "Server Enchantment" },
                    { value: "Specialized Killstreak Kit", text: "Specialized Killstreak Kit" },
                    { value: "Strange Filter", text: "Strange Filter" },
                    { value: "Strange Part", text: "Strange Part" },
                    { value: "Strangifier", text: "Strangifier" },
                    { value: "Supply Crate", text: "Supply Crate" },
                    { value: "Taunt 1", text: "Taunt 1" },
                    { value: "Tool", text: "Tool" },
                    { value: "Unlocked Crate", text: "Unlocked Crate" },
                    { value: "Unusualifier", text: "Unusualifier" },
                    { value: "Usable Item", text: "Usable Item" },
                    { value: "War Paint", text: "War Paint" },
                ]
            },
            quality: {
                selected: { value: null, text: null },
                options: [
                    { value: null, text: "Any" }, 
                    { value: "Unique", text: "Unique", color: "rgb(125, 109, 0)" }, 
                    { value: "Decorated Weapon", text: "Decorated Weapon", color: "rgb(250, 250, 250)", textshadow: "2px 2px 3px rgba(0,0,0,0.80)" }, 
                    { value: "Strange", text: "Strange", color: "rgb(207, 106, 50)" }, 
                    { value: "Vintage", text: "Vintage", color: "rgb(71, 98, 145)" }, 
                    { value: "Genuine", text: "Genuine", color: "rgb(77, 116, 85)" }, 
                    { value: "Unusual", text: "Unusual", color: "rgb(134, 80, 172)" }, 
                    { value: "Haunted", text: "Haunted", color: "rgb(56, 243, 171)" }, 
                    { value: "Collector's", text: "Collector's", color: "rgb(170, 0, 0)" }, 
                    { value: "Normal", text: "Normal" }, 
                    { value: "Self-Made", text: "Self-Made", color: "rgb(112, 176, 74)" }, 
                ]
            },
            color: {
                selected: { value: null, text: null },
                options: [
                    { value: null, text: "Any" }, 
                    { value: "A Color Similar to Slate", text: "A Color Similar to Slate", image: require("@/assets/tf2/paint_single_acolorsimilartoslate.png") }, 
                    { value: "A Deep Commitment to Purple", text: "A Deep Commitment to Purple", image: require("@/assets/tf2/paint_single_adeepcommitmenttopurple.png") }, 
                    { value: "A Distinctive Lack of Hue", text: "A Distinctive Lack of Hue", image: require("@/assets/tf2/paint_single_adistinctivelackofhue.png") }, 
                    { value: "A Mann's Mint", text: "A Mann's Mint", image: require("@/assets/tf2/paint_single_amann'smint.png") }, 
                    { value: "After Eight", text: "After Eight", image: require("@/assets/tf2/paint_single_aftereight.png") }, 
                    { value: "Aged Moustache Grey", text: "Aged Moustache Grey", image: require("@/assets/tf2/paint_single_anextraordinaryabundanceoftinge.png") }, 
                    { value: "An Extraordinary Abundance of Tinge", text: "An Extraordinary Abundance of Tinge", image: require("@/assets/tf2/paint_single_anextraordinaryabundanceoftinge.png") }, 
                    { value: "Australium Gold", text: "Australium Gold", image: require("@/assets/tf2/paint_single_australiumgold.png") }, 
                    { value: "Color No. 216-190-216", text: "Color No. 216-190-216", image: require("@/assets/tf2/paint_single_colorno.216-190-216.png") }, 
                    { value: "Dark Salmon Injustice", text: "Dark Salmon Injustice", image: require("@/assets/tf2/paint_single_darksalominjustice.png") }, 
                    { value: "Drably Olive", text: "Drably Olive", image: require("@/assets/tf2/paint_single_drablyolive.png") }, 
                    { value: "Indubitably Green", text: "Indubitably Green", image: require("@/assets/tf2/paint_single_induitablygreen.png") }, 
                    { value: "Mann Co. Orange ", text: "Mann Co. Orange", image: require("@/assets/tf2/paint_single_mannco.orange.png") }, 
                    { value: "Muskelmannbraun", text: "Muskelmannbraun", image: require("@/assets/tf2/paint_single_Muskelmannbraun.png") }, 
                    { value: "Noble Hatter's Violet", text: "Noble Hatter's Violet", image: require("@/assets/tf2/paint_single_noblehatter'sviolet.png") }, 
                    { value: "Peculiarly Drab Tincture", text: "Peculiarly Drab Tincture", image: require("@/assets/tf2/paint_single_peculiarlydrabtincture.png") }, 
                    { value: "Pink as Hell", text: "Pink as Hell", image: require("@/assets/tf2/paint_single_pinkashell.png") }, 
                    { value: "Radigan Conagher Brown", text: "Radigan Conagher Brown", image: require("@/assets/tf2/paint_single_radiganconagherbrown.png") }, 
                    { value: "The Bitter Taste of Defeat and Lime", text: "The Bitter Taste of Defeat and Lime", image: require("@/assets/tf2/paint_single_thebittertaseofdefeatandlime.png") }, 
                    { value: "The Color of a Gentlemann's Business Pants", text: "The Color of a Gentlemann's Business Pants", image: require("@/assets/tf2/paint_single_thecolorofagentlemansbusinesspants.png") }, 
                    { value: "Ye Olde Rustic Colour", text: "Ye Olde Rustic Colour", image: require("@/assets/tf2/paint_single_yeolderusticcolour.png") }, 
                    { value: "Zepheniah's Greed", text: "Zepheniah's Greed", image: require("@/assets/tf2/paint_single_zepheniah'sgreed.png") }, 
                    { value: "An Air of Debonair", text: "An Air of Debonair", image: require("@/assets/tf2/paint_team_anairofdebonair.png") }, 
                    { value: "Balaclavas Are Forever", text: "Balaclavas Are Forever", image: require("@/assets/tf2/paint_team_balaclavasareforever.png") }, 
                    { value: "Cream Spirit", text: "Cream Spirit", image: require("@/assets/tf2/paint_team_creamspirit.png") }, 
                    { value: "Operator's Overalls", text: "Operator's Overalls", image: require("@/assets/tf2/paint_team_operator'soveralls.png") }, 
                    { value: "Team Spirit", text: "Team Spirit", image: require("@/assets/tf2/paint_team_teamspirit.png") }, 
                    { value: "The Value of Teamwork", text: "The Value of Teamwork", image: require("@/assets/tf2/paint_team_thevalueofteamwork.png") }, 
                    { value: "Waterlogged Lab Coat", text: "Waterlogged Lab Coat", image: require("@/assets/tf2/paint_team_waterloggedlabcoat.png") }, 
                ]
            },
            craftable: {
                selected: { value: null, text: "Craft Status"},
                options: [
                    { value: "Any", text: "Any" },
                    { value: "Yes", text: "Craftable" },
                    { value: "No", text: "Uncraftable" },
                ]
            },
            tradelocked: {
                selected: { value: { min: 0, max: 7 }, text: "0 days - 7 days" },
                options: [
                    { value: { min: 0, max: 0 }, text: "Instant Only" },
                    { value: { min: 1, max: 7 }, text: "Trade Locked Only" },
                ]
            }
        }
    },
    components: {
        FilterBox,
        PriceRanger,
        SortBy,
    },
    async mounted() {
        console.log("TF2 Mounted");
        this.clearFilters()
        console.log("TF2 Cleared")
        if (this.$route.query.market_hash_name) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.$route.query.market_hash_name })
        }
        if (this.$route.query.query) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "query", value: this.$route.query.query })
        }
    },
    methods: {
        filterChanged(model, value) {
            // Reset to null if not selected.
            if (value && Array.isArray(value)) {                
                value = value.filter(x => !!x).map(x=> x.value).join(',').trim()
            } else {
                if (!value || !value.value) value = { value: null };
                value = value.value;
            }
            this.$store.dispatch("filters/updateFilter", { filter: model, value });
        },
        async clearFilters(){
            await this.$store.dispatch('filters/clearFilters');
            await this.$store.dispatch('filters/setFilterLoadedStatus', false);
            
            this.$refs.sortBy.reset();
            this.type.selected = { value: null, text: null };
            this.quality.selected = { value: null, text: null };
            this.color.selected = {value: null, text: null};
            this.craftable.selected = { value: null, text: null};
            this.tradelocked.selected = { value: null, text: "Any" };
            this.$refs.priceRanger.reset();
            // Emitting global event to be handled in advanced filters
            EventBus.$emit("filtersCleared")

            this.$store.dispatch('filters/setFilterLoadedStatus', true);
        }
    }
}
</script>
